<template>
	<div class="trackingpopupWrapper">

        <el-dialog v-model="dialogVisible" :title="popupTitle" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">

            <tracking-popup :data="data" :type="type" @close="itemEdited" @response="getResponse"></tracking-popup>

        </el-dialog>

	</div>
</template>

<script>
import trackingPopup from '../components/TrackingPopup.vue';

export default {
	name: 'TrackingPopupWrapper',

    components: {
        trackingPopup,
    },

    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: ''
        }
    },

	data: () => ({
		dialogVisible: true,
        popupTitle: 'Aufzeichnung erstellen'
	}),

	mounted() {
        if(this.type === 'edit') this.popupTitle = 'Aufzeichnung bearbeiten';
	},
	
	methods: {
        itemEdited(){
            this.$emit('close');
        },
        getResponse(data){
            this.$emit('response', data);
        },
	}
}
</script>

<style lang="scss">
.trackingpopupWrapper {
    .el-dialog {
        width: 90%;
    }
}
</style>