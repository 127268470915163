import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// import * as Sentry from "@sentry/vue";

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import Vue3TouchEvents from "vue3-touch-events";
import './registerServiceWorker'

createApp(App).use(router).use(store).use(ElementPlus).use(Vue3TouchEvents).mount('#app')

// Sentry.init({
//     App,
//     dsn: 'https://d6c2f624d99b4e6789903ce7020bc3fc@sentry.mave.at/2',
//     integrations: [
//       new Sentry.BrowserTracing({
//         // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//         tracePropagationTargets: ["localhost:8080", /^https:\/\/test-api\.mave\.at\/api\/v1/],
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       }),
//       new Sentry.Replay(),
//     ],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,

//     // Capture Replay for 10% of all sessions,
//     // plus for 100% of sessions with an error
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });