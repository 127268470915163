import { lmsApi } from "@/utils/axios-config";

export default {
    me() {
        return lmsApi.get(`/users/me`);
    },
    login(data) {
        return lmsApi.post(`/login`, data);
    },
    logout() {
        return lmsApi.post(`/logout`);
    },
    setpassword(data) {
        return lmsApi.post(`/setpassword`, data);
    },
    fetchTasktypes() {
        return lmsApi.get(`/task_types`);
    },
    resetPassword(data) {
        return lmsApi.post(`/password/reset`, data);
    },
    acceptInvite(data) {
        const token = data.token;
        delete data.token;
        return lmsApi.post(`/users/accept-invite/${token}`, data);
    },
    triggerPasswordReset(data) {
        return lmsApi.post(`/password/email`, data);
    },
    onboardingDone() {
        return lmsApi.post(`/users/me/onboarding-done`);
    }
};
