import { lmsApi } from "@/utils/axios-config";

export default {
    list() {
      return lmsApi.get(`/materials?picker=1`);
    },
    create(data) {
      return lmsApi.post(`/materials`, data);
    },
};
