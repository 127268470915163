<template>
	<div class="record content">

        <h1>Aufzeichnen</h1>

        <div class="record__content">
            <tracking-popup implementation="permanent" @response="scrollup"></tracking-popup>
        </div>

		<footer-bar />

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import footerBar from '../components/FooterBar.vue';
import trackingPopup from '../components/TrackingPopup.vue';
//import router from '../router';

export default {
	name: 'RecordView',

    components: {
        footerBar,
        trackingPopup,
    },

	data: () => ({
        fetchCounter: 0,
        counterInterval: false
	}),

	computed: {
		...mapGetters({
			user: 'user',
            sites: 'sites',
            materials: 'materials'
		}),
	},

	created() {
        if(this.sites.length < 1 || this.materials.length < 1){
            this.$store.commit('set_blockui', true);

            const that = this;
            this.counterInterval = setInterval(function(){
                if(that.sites.length < 1 || that.materials.length < 1) {
                    that.fetchCounter++;

                    if(that.fetchCounter > 3){
                        clearInterval(that.counterInterval);
                        that.$store.commit('set_blockui', false);
                    }

                    that.fetchData();
                } else {
                    that.$store.commit('set_blockui', false);
                }
            }, 1000);
        } else {
            this.$store.dispatch('fetchMaterials');
            this.$store.dispatch('fetchSites');
        }
	},
	
	methods: {
        fetchData(){
            if(this.materials.length < 1) this.$store.dispatch('fetchMaterials');
            if(this.sites.length < 1) this.$store.dispatch('fetchSites');
        },
        scrollup(){
            window.scrollTo(0, 0);
        }
	}
}
</script>

<style lang="scss">
.record {
    &__content {
        padding-bottom: 100px;
    }
}
</style>