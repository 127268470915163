import { lmsApi } from "@/utils/axios-config";

export default {
    getSites() {
		return lmsApi.get(`/construction_sites?per_page=1000&picker=1`);
    },
    getDetails(id) {
		return lmsApi.get(`/construction_sites/${id}`);
    },
	fetchFile(c_id, a_id) {
		return lmsApi.get(`/construction_sites/${c_id}/attachment/${a_id}`, {
			responseType: "blob"
		});
	},
    create(data) {
		return lmsApi.post(`/construction_sites`, data);
    },
};
