import axios from "axios";
import { ElMessage } from 'element-plus';
import store from '../store';

export const lmsApi = axios.create();
let preventUnauthorizedErrors = false;

var baseurl = (window.location.host.indexOf('local') >= 0) ? process.env.VUE_APP_API_BASE : '/api/v1/';

lmsApi.defaults.baseURL = baseurl;
lmsApi.defaults.headers.common["Accept"] = "application/json, text/plain, */*";

// add auth token to request headers
lmsApi.interceptors.request.use(function(config) {
	const authToken = localStorage.getItem('token');

	if (authToken && config.baseURL.indexOf(lmsApi.defaults.baseURL) === 0 && config.url !== '/login') {
		config.headers["Authorization"] = 'Bearer ' + authToken;
	}

	return config;
});


lmsApi.interceptors.response.use(function (response) {
	return response;
}, function (error) {
    if (error.request.status === 401) {
		if(!preventUnauthorizedErrors){
			store.dispatch('logout', true);
			ElMessage({
				message: 'Bitte neu authentifizieren.',
				type: 'error',
			});
			preventUnauthorizedErrors = true;

			setTimeout(function(){
				preventUnauthorizedErrors = false;
			}, 1000);
		}
	} else if(error.request.status === 403) {
		ElMessage({
			message: error.message,
			type: 'error',
		});
	} else if(error.request.status === 422) {
		ElMessage({
			message: error.response.data.message,
			type: 'error',
		});
    }
	return Promise.reject(error);
});