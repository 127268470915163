<template>
	<div class="login content">
		<div class="login__content">

			<div class="login__logo">
				<img :src="clientconfig.logo" />
			</div>

			<h1>Login</h1>

			<div class="login__wrapper">
				<div class="login__inputs">
					<span class="smallheadline">E-Mail</span>
					<el-input v-model="username" autocomplete="off" />
				</div>
				<div class="login__inputs">
					<span class="smallheadline">Passwort</span>
					<el-input v-model="password" type="password" autocomplete="off" />
				</div>
				<div class="login__inputs">
					<el-checkbox v-model="stay_logged_in" label="Eingeloggt bleiben" size="large" />
				</div>

				<el-button class="loginbutton" @click="login()">Anmelden</el-button>

				<router-link to="/forgotpassword" class="textlink login__forgot">Passwort vergessen?</router-link>

			</div>

		</div>

		<div v-if="showAddToHomeScreen" class="login__addtohome">
			<span class="close" @click="removeAddToHomescreen"></span>
			<span class="headline">
				<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-40q-33 0-56.5-23.5T200-120v-720q0-33 23.5-56.5T280-920h280v80H280v40h280v80H280v480h400v-80h80v200q0 33-23.5 56.5T680-40H280Zm0-120v40h400v-40H280Zm440-240L520-600l56-56 104 104v-288h80v288l104-104 56 56-200 200ZM280-800v-40 40Zm0 640v40-40Z"/></svg>
				Du verwendest mich öfter?
			</span>
			<p>Speicher dir die Website auf den Startbildschirm deines Handys um schneller Zugriff zu haben und die App auf dem vollen Screen zu verwenden.</p>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as api from "@/api";
import router from '../router';

export default {
	name: 'LoginView',

	data: () => ({
		username: '',
		password: '',
		stay_logged_in: false,
		triggerComputed: 0
	}),

	computed: {
		...mapGetters({
			user: 'user',
			clientconfig: 'clientconfig'
		}),
		showAddToHomeScreen(){
			let retval = false;
			console.log(this.triggerComputed)
			if(("standalone" in window.navigator && !window.navigator.standalone) || (!window.matchMedia('(display-mode: standalone)').matches)) retval = true;
			return retval && parseInt(localStorage.getItem('hideAddToHomescreen')) !== 2;
		}
	},

	created() {
		this.checkLogin();
		if(this.clientconfig === false) this.$store.dispatch('fetchClientConfig');
	},
	
	methods: {
		checkLogin(){
			const token = localStorage.getItem('token');
			const expiration = localStorage.getItem('expiresat');

			// check if there is a valid token in the local storage
			if(typeof token === 'string' && token.length > 0 && (new Date().getTime() / 1000) < expiration){
				this.fetchUser();
			}
		},
        async login(){
            try {
				const senddata = {
					email: this.username,
					password: this.password,
					stay_logged_in: this.stay_logged_in
				};
				const response = await api.user.login(senddata);

				localStorage.setItem('token', response.data.data.access_token);
				localStorage.setItem('expiresat', response.data.data.expiration);

				// fetch user and forward to dashboard
				this.fetchUser();
            } catch (error) {
				console.error(error);
            }
        },
		async fetchUser(){
			await this.$store.dispatch('fetchMe');
			if(this.user !== false){
				router.push({ name: "DashboardView" });
			}
		},
		removeAddToHomescreen(){
			this.triggerComputed = new Date().getTime();
			localStorage.setItem('hideAddToHomescreen', 2);
		}
	}
}
</script>

<style lang="scss">

</style>