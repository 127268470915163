import { lmsApi } from "@/utils/axios-config";

export default {
    create(data) {
      return lmsApi.post(`/alerts/mine`, data);
    },
    done(id) {
      return lmsApi.post(`/alerts/${id}/done`);
    },
    get(){
      return lmsApi.get(`/alerts/mine?per_page=1000`);
    }
};
