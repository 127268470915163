import { createStore } from 'vuex'
import * as api from "@/api";
import router from '../router';

export default createStore({
	state: {
		user: false,
		blockui: false,
		selectedUser: false,
		sites: [],
		recentSites: [],
		materials: [],
		recentMaterials: [],
		tasktypes: [],
		days: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
		clientconfig: false,
		permissions: {
			admin: false
		},

		showAlert: false,
		showSettings: false,
		onboarding: false,
	},
	getters: {
		user: ({user}) => user,
		selectedUser: ({selectedUser}) => selectedUser,
		sites: ({sites}) => sites,
		materials: ({materials}) => materials,
		blockui: ({blockui}) => blockui,
		tasktypes: ({tasktypes}) => tasktypes,
		showAlert: ({showAlert}) => showAlert,
		showSettings: ({showSettings}) => showSettings,
		days: ({days}) => days,
		clientconfig: ({clientconfig}) => clientconfig,
		permissions: ({permissions}) => permissions,
		recentSites: ({recentSites}) => recentSites,
		recentMaterials: ({recentMaterials}) => recentMaterials,
		onboarding: ({onboarding}) => onboarding,
	},
	mutations: {
		set_blockui(state, val){ 
			state.blockui = val;
		},
		set_user(state, val){ 
			state.user = val;
		},
		set_selectedUser(state, val){ 
			state.selectedUser = val;
		},
		set_sites(state, val){ 
			state.sites = val;
		},
		set_materials(state, val){ 
			state.materials = val;
		},
		set_tasktypes(state, val){ 
			state.tasktypes = val;
		},
		set_showAlert(state, val){ 
			state.showAlert = val;
		},
		set_showSettings(state, val){ 
			state.showSettings = val;
		},
		set_clientconfig(state, val){ 
			state.clientconfig = val;
		},
		set_permissions(state, val){ 
			state.permissions = val;
		},
		set_recentSites(state, val){ 
			state.recentSites = val;
		},
		set_recentMaterials(state, val){ 
			state.recentMaterials = val;
		},
		set_onboarding(state, val){ 
			state.onboarding = val;
		},
	},
	actions: {
		async logout (context, force = false) {
			if(!force) await api.user.logout();
			context.commit('set_user', false);
			localStorage.removeItem('token');
			router.push({ name: "LoginView" });
		},
		async fetchMe (context) {
            try {
				const response = await api.user.me();
				context.commit('set_user', response.data.data);

				const permissions = { admin: response.data.data.permissions.indexOf('admin') > -1 };
				context.commit('set_permissions', permissions);

				if(response.data.data.onboarding_done_at === null){
					context.commit('set_onboarding', true);
				}

				context.dispatch('fetchTasktypes');
            } catch (error) {
                console.error(error);
            }
		},
		async fetchTasktypes (context) {
            try {
				const response = await api.user.fetchTasktypes();
				context.commit('set_tasktypes', response.data.data);
            } catch (error) {
                console.error(error);
            }
		},
		async fetchMaterials (context) {
            try {
				const response = await api.material.list();
				context.commit('set_materials', response.data.data);
				context.commit('set_recentMaterials', response.data.recent);
            } catch (error) {
                console.error(error);
            }
		},
		async fetchSites (context) {
            try {
				const response = await api.constructionsite.getSites();
				const helpdata = response.data.data;

				helpdata.sort((a, b) => {
					let retval = 0;
					if (a.name < b.name) {
						retval = -1;
					} else if (a.name > b.name) {
						retval = 1;
					}
				
					return retval;
				});


				context.commit('set_recentSites', response.data.recent);
				context.commit('set_sites', helpdata);
            } catch (error) {
                console.error(error);
            }
		},
		async fetchSiteDetails (context, id) {
            try {
				const response = await api.constructionsite.getDetails(id);
				return response.data;
            } catch (error) {
                console.error(error);
            }
		},

		async fetchClientConfig (context) {
            try {
				const response = await api.client.getConfig();
				context.commit('set_clientconfig', response.data.data);
				context.dispatch('setClientConfig');
            } catch (error) {
                console.error(error);
            }
		},
		setClientConfig (context) {
			var r = document.querySelector(':root');
			r.style.setProperty('--ci', context.state.clientconfig.color);
		},
		async onboardingDone (context) {
			context.commit('set_onboarding', false);
			
			try {
				await api.user.onboardingDone();
			} catch (error) {
				console.error(error);
			}
		}
	},
	modules: {
	}
})
