import { lmsApi } from "@/utils/axios-config";

export default {
    getTasks() {
      return lmsApi.get(`/tasks/mine?per_page=1000`);
    },
    getTask(id) {
      return lmsApi.get(`/task/${id}`);
    },
    createTask(data) {
      return lmsApi.post(`/tasks/mine`, data);
    },
    updateTask(id, data) {
      return lmsApi.post(`/tasks/${id}`, data);
    },
    deleteTask(id) {
      return lmsApi.delete(`/tasks/${id}`);
    },
    getWeek(date) {
      return lmsApi.get(`/tasks/mine/stats/week?day=` + parseInt(date.getTime() / 1000));
    }
};
