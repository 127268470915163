import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import RecordView from '../views/RecordView.vue'
import HistoryView from '../views/HistoryView.vue'
import SetpasswordView from '../views/SetpasswordView.vue'
import DetailView from '../views/DetailView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: {
      navigation: false
    }
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPasswordView',
    component: ForgotPasswordView,
    meta: {
      navigation: false
    }
  },
  {
    path: '/',
    name: 'DashboardView',
    component: DashboardView,
    meta: {
      navigation: true
    }
  },
  {
    path: '/record',
    name: 'RecordView',
    component: RecordView,
    meta: {
      navigation: true
    }
  },
  {
    path: '/history',
    name: 'HistoryView',
    component: HistoryView,
    meta: {
      navigation: true
    }
  },
  {
    path: '/details',
    name: 'DetailView',
    component: DetailView,
    meta: {
      navigation: true
    }
  },
  {
    path: '/setpassword',
    name: 'SetpasswordView',
    component: SetpasswordView,
    meta: {
      navigation: false
    }
  },
  {
    path: '/password-reset',
    name: 'ResetpasswordView',
    component: SetpasswordView,
    meta: {
      navigation: false
    }
  }
]

const router = createRouter({
  history: createWebHistory('/app/'),
  routes
})

export default router
