<template>
  <div class="trackingpopup" :class="'trackingpopup--' + implementation">
    <select-popup
      :data="selectPopupData"
      :value="selectedValue"
      :selected="selectedValues"
      :datatype="selectPopupType"
      @close="dialogClosed"
    ></select-popup>

    <p v-if="sites.length < 1" class="nodata">Keine Baustellen vorhanden!</p>
    <p v-else-if="form.task_types.length < 1" class="nodata">Keine Task-Typen vorhanden!</p>

    <el-form v-if="sites.length > 0 && form.task_types.length > 0" :model="form">
      <el-form-item>
        <span class="input__label">Baustelle</span>
        <div
          class="input__wrap"
          :class="{ error: errors.indexOf('construction_site_id') >= 0 }"
        >
          <input
            type="text"
            :value="siteById"
            @click="
              openSelect(
                'construction_site_id',
                { all: sites, recent: recentSites },
                this.form.construction_site_id,
                []
              )
            "
            placeholder="Bitte auswählen .."
            readonly
          />
        </div>
      </el-form-item>

      <el-form-item>
        <span class="input__label">Datum</span>
        <div class="input__wrap input__wrap--date">
          <input type="date" v-model="form.date" required />
        </div>
      </el-form-item>

      <el-form-item>
        <div v-for="t in form.task_types" :key="t.id" class="input__tasktype">
          <div
            @click="toggleTaskType(t.id)"
            class="input__tasktype__item"
            :class="{ active: activeTasktypes.indexOf(t.id) >= 0 }"
          >
            {{ t.tasktype }}
          </div>
          <input
            :id="'tasktype_' + t.id"
            type="number"
            step="0.25"
            inputmode="decimal"
            min="0"
            v-model="t.amount"
            class="input__tasktype__input"
            :disabled="activeTasktypes.indexOf(t.id) < 0"
            :class="{ active: activeTasktypes.indexOf(t.id) >= 0 }"
            style="text-align: right !important"
          />
        </div>
        <div class="input__tasktype__calculation">
          <span class="input__tasktype__calculation__label">Gesamt</span>
          <span class="input__tasktype__calculation__time"
            >{{ completeTime }}h</span
          >
        </div>
      </el-form-item>

      <el-form-item>
        <span class="input__label">Zusatzinformationen</span>
        <div class="input__wrap input__wrap--noicon">
          <textarea v-model="form.description"></textarea>
        </div>
      </el-form-item>

      <el-form-item>
        <span class="input__label">Material</span>

        <p
          v-if="materials.length < 1"
          class="nodata nodata--forcefull nodata--narrow"
        >
          Keine Materialdaten vorhanden!
        </p>

        <div v-if="materials.length > 0" class="materiallist">
          <el-switch
            v-model="form.is_material_return"
            size="small"
            active-text="Material Retoure"
            active-color="#1f1f1f"
            class="materiallist__switch"
          />

          <div
            v-for="(m, i) in form.materials"
            :key="'material_' + i"
            class="split split--material"
          >
            <input
              type="number"
              v-model="m.amount"
              inputmode="numeric"
              min="0"
              step="0.1"
            />
            <input
              type="text"
              :value="materialById(m.material_id)"
              @click="
                openSelect(
                  'materials',
                  { all: materials, recent: recentMaterials },
                  m.material_id,
                  selectedMaterial
                );
                materialIndex = i;
              "
              readonly
            />
          </div>

          <span @click="addMaterial" class="plus">+ Material hinzufügen</span>
        </div>
      </el-form-item>
    </el-form>

    <span
      v-if="sites.length > 0 && form.task_types.length > 0"
      class="trackingpopup__footer"
      :class="{
        'trackingpopup__footer--withcheck': implementation === 'permanent',
      }"
    >
      <el-button @click="saveData(false)" type="primary">Speichern</el-button>
      <el-button
        v-if="implementation === 'permanent'"
        @click="saveDataAndFinalizeSite"
        type="primary"
        class="ghost"
      >
        <svg
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          data-v-ea893728=""
        >
          <path
            fill="currentColor"
            d="M280.768 753.728 691.456 167.04a32 32 0 1 1 52.416 36.672L314.24 817.472a32 32 0 0 1-45.44 7.296l-230.4-172.8a32 32 0 0 1 38.4-51.2l203.968 152.96zM736 448a32 32 0 1 1 0-64h192a32 32 0 1 1 0 64H736zM608 640a32 32 0 0 1 0-64h319.936a32 32 0 1 1 0 64H608zM480 832a32 32 0 1 1 0-64h447.936a32 32 0 1 1 0 64H480z"
          ></path>
        </svg>
      </el-button>
      <span
        v-if="implementation !== 'permanent'"
        class="textlink"
        @click="closeDialog"
        >Abbrechen</span
      >
    </span>
  </div>
</template>

<script>
import * as api from "@/api";
import selectPopup from "../components/SelectPopup.vue";

import { mapGetters } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "TrackingPopup",

  components: {
    selectPopup,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "",
    },
    implementation: {
      type: String,
      default: "default",
    },
  },

  data: () => ({
    activeTasktypes: [],
    form: {
      id: 0,
      construction_site_id: 0,
      date: "",
      description: "",
      materials: [
        {
          amount: 0,
          material_id: "",
        },
      ],
      task_types: [],
      is_material_return: false,
    },
    errors: [],
    trackType: "task",

    selectedValue: 0,
    selectedValues: [],
    selectPopupData: { all: [], recent: [] },
    selectPopupType: "",
    materialIndex: -1,
  }),

  computed: {
    ...mapGetters({
      sites: "sites",
      recentSites: "recentSites",
      recentMaterials: "recentMaterials",
      materials: "materials",
      user: "user",
      tasktypes: "tasktypes",
    }),
    siteById() {
      let retval = "";
      this.sites.forEach((s) => {
        if (Number(s.id) === Number(this.form.construction_site_id)) {
          retval = s.name;
        }
      });
      return retval;
    },
    completeTime() {
      let retval = 0;
      this.form.task_types.forEach((t) => {
        let helpAmount = Number(String(t.amount).replace(",", "."));
        retval += helpAmount;
      });
      retval = retval.toFixed(2);
      return String(retval).replace(".", ",");
    },
    selectedMaterial() {
      const selectedMaterial = [];
      this.form.materials.forEach((m) => {
        if (Number(m.material_id) > 0) selectedMaterial.push(m.material_id);
      });
      return selectedMaterial;
    },
  },

  watch: {
    tasktypes() {
      if (
        typeof this.tasktypes !== "undefined" &&
        this.tasktypes.length > 0 &&
        this.form.task_types.length === 0
      )
        this.resetTasktypes();
    },
  },

  mounted() {
    if (
      Object.hasOwn(this.data, "construction_site_id") &&
      Number(this.data.construction_site_id) > 0
    ) {
      this.form = {
        ...this.form,
        ...this.data,
      };

      this.form.task_types.forEach((t) => {
        if (Number(t.amount) > 0) {
          this.activeTasktypes.push(t.id);
        }
      });

      this.form.description =
        this.form.description === null ? "" : this.form.description;
    } else {
      this.form.date = this.createCurrentDate();
    }

    if (
      typeof this.tasktypes !== "undefined" &&
      this.tasktypes.length > 0 &&
      this.form.task_types.length === 0
    )
      this.resetTasktypes();
  },

  methods: {
    // start select popup
    openSelect(type, data, value, selected) {
      this.selectedValues = selected;
      this.selectedValue = isNaN(value) ? 0 : Number(value);
      this.selectPopupData = data;
      this.selectPopupType = type;
    },
    dialogClosed(data) {
      this.selectPopupData = { all: [], recent: [] };

      if (data === false) {
        console.log("just close select popup");
      } else if (data.type === "materials") {
        this.form.materials[this.materialIndex].material_id = data.id;
      } else {
        this.form[data.type] = data.id;
      }
    },
    // end select popup

    // start task types
    toggleTaskType(id) {
      var index = this.activeTasktypes.indexOf(id);
      if (index !== -1) {
        this.activeTasktypes.splice(index, 1);
        this.form.task_types.forEach((t) => {
          if (Number(t.id) === Number(id)) t.amount = 0;
        });
        document
          .getElementById("tasktype_" + id)
          .setAttribute("disabled", true);
      } else {
        this.activeTasktypes.push(id);
        document.getElementById("tasktype_" + id).removeAttribute("disabled");
        document.getElementById("tasktype_" + id).focus();
      }
    },
    resetTasktypes() {
      this.form.task_types = [];
      this.tasktypes.forEach((t) => {
        this.form.task_types.push({
          id: t.id,
          amount: 0,
          tasktype: t.name,
        });
      });
    },
    apiFormatTasktypes() {
      let retval = [];
      this.form.task_types.forEach((t) => {
        if (!isNaN(t.amount) && Number(t.amount) > 0) {
          retval.push({
            task_type_id: t.id,
            amount: t.amount,
          });
        }
      });
      return retval;
    },
    // end task types

    materialById(id) {
      let retval = "";
      this.materials.forEach((m) => {
        if (Number(m.id) === Number(id)) {
          retval = m.name;
        }
      });
      return retval;
    },

    closeDialog() {
      this.$emit("close");
    },
    createCurrentDate() {
      let today = new Date();
      let y = today.getFullYear();
      let m =
        Number(today.getMonth() + 1) < 10
          ? "0" + Number(today.getMonth() + 1)
          : Number(today.getMonth() + 1);
      let d =
        Number(today.getDate()) < 10 ? "0" + today.getDate() : today.getDate();

      return y + "-" + m + "-" + d;
    },
    addMaterial() {
      this.form.materials.push({
        amount: 0,
        material_id: "",
      });
    },
    validateData() {
      this.errors = [];

      // constructionsite
      if (
        Number(this.form.construction_site_id) === 0 ||
        isNaN(this.form.construction_site_id)
      ) {
        window.scrollTo(0, 0);
        this.errors.push("construction_site_id");
      }
    },
    resetData() {
      this.form.id = 0;
      this.form.construction_site_id = 0;
      this.form.date = this.createCurrentDate();
      this.form.description = "";
      this.form.is_material_return = false;
      this.form.materials = [
        {
          amount: 0,
          material_id: "",
        },
      ];

      this.errors = [];
      this.selectPopupData = { all: [], recent: [] };
      this.materialIndex = -1;

      this.activeTasktypes = [];
      this.resetTasktypes();
    },
    saveDataAndFinalizeSite() {
      ElMessageBox.confirm(
        "Bist du sicher, dass du die Baustelle <b>als abgeschlossen markieren</b> willst?",
        "Achtung",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Speichern",
          cancelButtonText: "Abbrechen",
          type: "warning",
        }
      )
        .then(() => {
          this.saveData(true);
        })
        .catch(() => {
          console.log("saving canceled");
        });
    },
    async saveData(completeSite = false) {
      try {
        let response = false;
        let senddata = JSON.parse(JSON.stringify(this.form));

        senddata.task_types = this.apiFormatTasktypes();
        // senddata.user_id = this.user.id;

        // filter material
        for (var i = senddata.materials.length - 1; i >= 0; i--) {
          if (
            isNaN(senddata.materials[i].amount) ||
            Number(senddata.materials[i].amount) <= 0 ||
            isNaN(senddata.materials[i].material_id) ||
            Number(senddata.materials[i].material_id) <= 0
          ) {
            senddata.materials.splice(i, 1);
          }
        }
        if (senddata.materials.length < 1) delete senddata.materials;

        this.validateData();

        if (this.errors.length < 1) {
          if (this.form.id === 0) {
            if (completeSite) senddata.construction_site_is_finished = true;
            response = await api.task.createTask(senddata);
          } else {
            response = await api.task.updateTask(this.form.id, senddata);
          }

          ElMessage({
            message: "Task erfolgreich gespeichert.",
            type: "success",
          });

          this.$emit("response", response.data);
          if (this.implementation !== "permanent") {
            this.closeDialog();
          } else {
            this.resetData();
          }

          // always fetch sites/materials after saving, to stay uptodate with the recent sites
          this.$store.dispatch("fetchSites");
          this.$store.dispatch("fetchMaterials");
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.trackingpopup {
  .input__wrap input,
  select {
    width: 100%;
    cursor: pointer;
  }

  .split {
    width: 100%;

    .el-form-item__content {
      display: flex;
      justify-content: space-between;

      input,
      select {
        margin: 0;
        box-sizing: border-box;
      }
    }

    &--material {
      overflow: hidden;
      margin-top: 3px;

      > :first-child {
        width: calc(20% - 5px);
        float: left;
      }
      > :last-child {
        width: calc(80% - 5px);
        float: right;
        cursor: pointer;
      }
    }
  }

  .plus {
    display: block;
    width: 100%;
    background: #efefef;
    border-radius: 6px;
    font-weight: bold;
    padding: 6px;
    margin-top: 15px;
    cursor: pointer;
    box-sizing: border-box;
  }

  .error {
    select,
    input {
      border: solid 1px red !important;
    }
  }

  &__footer {
    display: flex;
    gap: 10px;
    padding-top: 20px;

    .textlink {
      min-width: 76px;
    }

    &--withcheck {
      gap: 0;

      :last-child {
        width: 45px !important;
        padding: 0 !important;
        position: relative;

        svg {
          width: 20px !important;
          height: 20px;
          color: black;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .el-select {
    .el-input {
      &__wrapper {
        box-shadow: 0 0 0 1px #999 inset !important;
      }
      &__inner {
        border: none !important;
        padding: 17px 0;
      }
    }
  }

  &--permanent {
    .el-overlay {
      background: none;
    }
    .el-dialog {
      box-shadow: none;
      width: 100%;
    }

    .trackingpopup {
      &__footer {
        .el-button {
          width: 100%;
        }
      }
    }
  }
  &--default {
    padding-bottom: 10px !important;
  }

  .materiallist {
    width: 100%;

    &__switch {
      margin-bottom: 8px;
      width: 100%;

      .el-switch__label.is-active {
        color: $color-black;
      }
    }
  }
}

.el-select-dropdown__item {
  font-family: arial;
}
</style>
