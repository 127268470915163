<template>
	<div class="records content">

        <h1>
            Aufzeichnungen
            
            <span v-if="listView" @click="switchView" class="viewswitch">
                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M600 896q-134 0-227-93t-93-227q0-134 93-227t227-93q134 0 227 93t93 227q0 134-93 227t-227 93Zm-.235-60Q708 836 784 760.235q76-75.764 76-184Q860 468 784.235 392q-75.764-76-184-76Q492 316 416 391.765q-76 75.764-76 184Q340 684 415.765 760q75.764 76 184 76ZM702 719l42-42-113-114-1-147h-60v172l132 131ZM80 446v-60h160v60H80ZM40 606v-60h200v60H40Zm40 160v-60h160v60H80Zm520-190Z"/></svg>
            </span>

            <span v-if="!listView" @click="switchView" class="viewswitch">
                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M120 816v-60h240v60H120Zm0-210v-60h480v60H120Zm0-210v-60h720v60H120Z"/></svg>
            </span>
        </h1>

        <div v-if="listView">
            <div v-for="(v, i) in historyData" :key="'hd_' + v.id" class="records__item__wrap js-record-item" v-touch:swipe.left="swipeHandler" v-touch:swipe.right="swipeHandler" :class="{ 'active' : selectedId === v.id }" :data-id="v.id">
                <div class="records__item">
                    <span class="records__item__date smallheadline">{{ v.date }}</span>
                    <span class="records__item__site">{{ constructionsiteById(v.construction_site_id) }}</span>
                    <span class="records__item__task">{{ v.description }}</span>
                </div>
                <div class="records__del smallheadline" @click="deleteRecord(v.id, i)"><span>Del</span></div>
                <div class="records__edit smallheadline" @click="editRecord(i)"><span>Edit</span></div>
                <div class="records__cancel smallheadline" @click="cancelEdit()"><span>Cancel</span></div>
            </div>

            <div v-if="historyData.length < 1 && !blockui" class="records__nodata">
                Keine bisherigen Aufzeichnungen
            </div>
        </div>

        <div v-else class="weekview">
            <ui-block v-if="weekViewLoader" :global="false"></ui-block>
            <div v-else>
                <div class="weekview__week">
                    <div class="weekview__week__title">
                        <div @click="viewWeek(false)" class="weekview__week__title__buttons"><svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg></div>
                        {{ weekViewFrom }} - {{ weekViewTo }}
                        <div @click="viewWeek(true)" class="weekview__week__title__buttons"><svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg></div>
                    </div>
                    <div v-for="(day, i, index) in weekViewData" :key="'weekday_' + i" class="weekview__week__day">
                        <div v-for="(item, j) in day" :key="'weekday_item_' + j" class="weekview__week__day__item" :style="'width:' + calcDayItemWidth(Number(item.amount)) + '%;'">{{ item.name }}</div>
                        <div class="weekview__week__day__count">{{ daySum(day) }}h</div>
                        <span class="weekview__week__day__day">{{ days[index] }}</span>
                    </div>
                </div>
            </div>
        </div>

        <tracking-popup-wrapper v-if="showTracking" :data="recordData" type="edit" @close="itemEdited" @response="getResponse"></tracking-popup-wrapper>

		<footer-bar />

	</div>
</template>

<script>
import * as api from "@/api";

import { mapGetters } from 'vuex';
import footerBar from '../components/FooterBar.vue';
import trackingPopupWrapper from '../components/TrackingPopupWrapper.vue';
//import router from '../router';

import uiBlock from '../components/UiBlock.vue';

export default {
	name: 'HistoryView',

    components: {
        uiBlock,
        footerBar,
        trackingPopupWrapper,
    },

	data: () => ({
        showTracking: false,
        recordData: {},
        selectedId: 0,

        historyData: [],

        fetchCounter: 0,
        counterInterval: false,
        historyfetched: false,

        listView: true,
        weekViewLoader: true,
        weekViewDate: new Date(),
        weekViewData: {},
        weekViewHighestValue: 8,
        weekViewFrom: '',
        weekViewTo: '',
	}),

	computed: {
        ...mapGetters({
			sites: 'sites',
            materials: 'materials',
            tasktypes: 'tasktypes',
            days: 'days',
            blockui: 'blockui'
		})
	},

	mounted() {
        this.$store.commit('set_blockui', true);

        if(this.sites.length < 1 || this.materials.length < 1 || this.historyfetched === false) {
            this.fetchCounter++;

            if(this.fetchCounter > 7){
                alert('Bitte Admin kontaktieren!');
                clearInterval(this.counterInterval);
            }

            this.fetchData();
        } else {
            this.$store.commit('set_blockui', false);
        }
	},
	
	methods: {
		constructionsiteById (id) {
            let site = false;
            if(this.sites.length > 0) site = this.sites.find(site => site.id == id);
            return (typeof site !== 'undefined' && site !== false && site.name) ? site.name : '';
        },

        switchView(){
            this.listView = !this.listView;
            if(!this.listView) this.loadWeek();
        },
        getWeekObject(timestamp){
            const currentDate = new Date(timestamp);
            const currentDayOfWeek = currentDate.getDay();
            const offset = currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek;

            const startDate = new Date(currentDate);
            startDate.setDate(startDate.getDate() + offset);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6);

            const dates = {};
            for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
                const helpDate = new Date(date);
                const y = helpDate.getFullYear();
                const m = (helpDate.getMonth() + 1) < 10 ? '0' + (helpDate.getMonth() + 1) : (helpDate.getMonth() + 1);
                const d = helpDate.getDate() < 10 ? '0' + helpDate.getDate() : helpDate.getDate();
                dates[y+'-'+m+'-'+d] = [];
            }

            return dates;
        },
        async loadWeek(){
            this.weekViewLoader = true;
            this.weekViewHighestValue = 8;

            try {
                const response = await api.task.getWeek(this.weekViewDate);
                const emptyWeek = this.getWeekObject(this.weekViewDate);
                this.weekViewData = {
                    ...emptyWeek,
                    ...response.data.data
                }
                let first = true;

                for(const [key] of Object.entries(this.weekViewData)){
                    let hoursPerDay = 0;
                    
                    if(first){
                        this.weekViewFrom = key;
                        first = false;
                    }
                    this.weekViewTo = key;

                    this.weekViewData[key].forEach(day => {
                        hoursPerDay += Number(day.amount);
                    });
                    if(hoursPerDay > this.weekViewHighestValue) this.weekViewHighestValue = hoursPerDay;
                }

                this.weekViewLoader = false;
            } catch (error) {
                console.error(error);
            }
        },
        daySum(day){
            let retval = 0;
            day.forEach(d => {
                retval += Number(d.amount);
            });
            return String(retval.toFixed(2)).replace('.',',');
        },
        calcDayItemWidth(hours){
            let retval = 0;

            if(hours > 0){
                let percentage = Math.floor(hours / (this.weekViewHighestValue / 100))
                retval = 0.83 * percentage;
            }

            return retval;
        },
        viewWeek(forward){
            let dir = (forward) ? 7 : -7;
            this.weekViewDate.setDate(this.weekViewDate.getDate() + dir);
            this.loadWeek();
        },

        fetchData(){
            if(this.materials.length < 1) this.$store.dispatch('fetchMaterials');
            if(this.sites.length < 1) this.$store.dispatch('fetchSites');
            if(this.historyfetched === false) this.fetchHistoryData();
        },

        async fetchHistoryData(){
            try {
                this.$store.commit('set_blockui', true);
                let response = await api.task.getTasks();
                this.historyData = response.data.data.sort((a,b) => b.id - a.id);
                this.historyfetched = true;
                this.$store.commit('set_blockui', false);
            } catch (error) {
                console.error(error);
            }
        },

        swipeHandler(item, i) {
            let el = i.target;

            if(!el.classList.contains('js-record-item')){
                el = i.target.closest('.js-record-item');
            }
            
            if(item === 'left'){
                this.cancelEdit();

                el.classList.add('active');
                this.selectedId = Number(el.dataset.id);
            } else if(item === 'right'){
                el.classList.remove('active');
            }
        },

        editRecord(index) {
            this.recordData = JSON.parse(JSON.stringify(this.historyData[index]));
            
            // preset material
            if(this.recordData.materials.length <= 0){
                this.recordData.material = [{
                    amount: 0,
                    material_id: ''
                }];
            } else {
                let tempdata = [];
                this.recordData.materials.forEach(m => {
                    tempdata.push({
                        amount: Number(m.amount),
                        material_id: Number(m.id)
                    });
                });
                this.recordData.materials = tempdata;
            }

            // preset tasktype
            let tempdata = [];
            this.tasktypes.forEach(t => {
                tempdata.push({
                    id: t.id,
                    amount: 0,
                    tasktype: t.name
                });
            });
            
            this.recordData.task_types.forEach(t => {
                if (tempdata.filter(i => i.id === t.id).length < 1) {
                    tempdata.push({
                        id: t.id,
                        amount: t.amount,
                        tasktype: t.name
                    });
                } else {
                    tempdata.forEach(temp => {
                        if(temp.id === t.id){
                            temp.amount = t.amount;
                        }
                    });
                }
            });

            this.recordData.task_types = tempdata;

            this.showTracking = true;
        },

        async deleteRecord(id, index) {
            if(confirm('Willst du den Eintrag wirklich löschen?')){
                this.historyData.splice(index, 1);

                try {
                    await api.task.deleteTask(id);
                } catch (error) {
                    console.error(error);
                }
            }
        },

        getResponse(data){
            let i = -1;
            this.historyData.forEach(function(d, index){
                if(d.id === data.id) i = index;
            });
            this.historyData[i] = data;
        },

        itemEdited(){
            this.showTracking = false;
            this.selectedId = 0;

            document.querySelectorAll('.js-record-item').forEach(i => {
                i.classList.remove('active');
            });
            this.fetchHistoryData();
        },

        cancelEdit(){
            document.querySelectorAll('.js-record-item').forEach(i => {
                i.classList.remove('active');
            });
        }
	}
}
</script>

<style lang="scss">
.records {
    &__nodata {
        text-align: center;
        opacity: .3;
        margin: 20px 0;
        font-style: italic;
        font-size: 14px;
    }
    &__item {
        display: block;
        background: #eee;
        text-align: left;
        padding: 12px 15px;
        line-height: 1.3;
        position: relative;
        z-index: 10;
        border-radius: 6px;
        transition: all .3s ease;

        &__date {
            display: block;
            float: left;
            margin-bottom: 6px;
        }
        &__site {
            display: block;
            clear: both;
            font-weight: bold;
            margin-bottom: 4px;
            font-size: 15px;
        }
        &__task {
            display: block;
            clear: both;
            font-size: 14px;
        }

        &__wrap {
            overflow: hidden;
            position: relative;
            margin-bottom: 10px;
            border-radius: 6px;
            z-index: 5;

            &.active {
                .records {
                    &__item {
                        transform: translateX(-210px);
                    }
                    &__edit,
                    &__cancel,
                    &__del {
                        opacity: 1;
                        transition: all .15s linear 0s;
                    }
                }
            }
        }
    }

    &__edit,
    &__cancel,
    &__del {
        position: absolute;
        right: 0;
        width: 76px;
        background: rgba(255, 82, 82, 1);
        height: 100%;
        top: 0;
        z-index: 5;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        box-sizing: border-box;
        padding-left: 6px;
        color: white;
        display: flex;
        align-items: stretch;
        opacity: 0;
        transition: all .15s ease .15s;
        cursor: pointer;

        span {
            display: block;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &__edit {
        right: 70px;
        z-index: 6;
        background: #426ca3;
    }
    &__cancel {
        right: 140px;
        z-index: 7;
        background: #999;
    }

    .viewswitch {
        float: right;
        transform: translateY(-2px);
        cursor: pointer;

        svg {
            width: 32px;
            height: 32px;
        }
    }
}

.weekview {
    position: relative;
    width: 100%;
    min-height: 100px;

    &__week {
        text-align: left;

        &__title {
            font-weight: bold;
            margin-bottom: 30px;
            text-align: center;
            position: relative;

            &__buttons {
                position: absolute;
                left: 0;
                top: 0;
                width: 30px;
                cursor: pointer;

                > svg {
                    transform: translateY(-1px);
                }

                &:last-child {
                    right: 0;
                    left: auto;

                    > svg {
                        transform: translateY(-1px) rotate(180deg);
                    }
                }
            }
        }

        &__day {
            width: 100%;
            height: 35px;
            clear: both;
            background-color: #f5f5f5;
            border-radius: 6px;
            margin: 20px 0;
            position: relative;

            &__day {
                position: absolute;
                top: -15px;
                font-size: 10px;
                color: black;
                left: 0;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: bold;
            }
            &__item {
                float: left;
                height: 100%;
                overflow: hidden;
                box-sizing: border-box;
                line-height: 36px;
                font-size: 11px;
                color: black;
                padding: 0 7px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                &:nth-child(1) { background: #777; border-top-left-radius: 6px; border-bottom-left-radius: 6px; }
                &:nth-child(2) { background: #888; }
                &:nth-child(3) { background: #999; }
                &:nth-child(4) { background: #aaa; }
                &:nth-child(5) { background: #bbb; }
                &:nth-child(6) { background: #ccc; }
                &:nth-child(7) { background: #ddd; }
                &:nth-child(8) { background: #eee; }
            }
            &__count {
                float: left;
                font-weight: bold;
                color: black;
                line-height: 36px;
                font-size: 13px;
                padding-left: 10px;
            }
        }
    }
}
</style>