<template>
	<div v-if="onboarding" class="onboarding">
        <div class="onboarding__content">
            <div class="onboarding__remove"><span @click="handleClose()">Nicht mehr anziegen</span></div>
            <swiper
                @swiper="onSwiper"
                :modules="modules"
                :slides-per-view="1"
                :space-between="0"
                :pagination="{type: 'fraction'}"
            >
                <swiper-slide>
                    <div class="swiper-slide-content">
                        <svg class="mainicon" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="m430-500 283-283q12-12 28-12t28 12q12 12 12 28t-12 28L487-444l-57-56Zm99 99 254-255q12-12 28.5-12t28.5 12q12 12 12 28.5T840-599L586-345l-57-56ZM211-211q-91-91-91-219t91-219l120-120 59 59q7 7 12 14.5t10 15.5l148-149q12-12 28.5-12t28.5 12q12 12 12 28.5T617-772L444-599l-85 84 19 19q46 46 44 110t-49 111l-57-56q23-23 25.5-54.5T321-440l-47-46q-12-12-12-28.5t12-28.5l57-56q12-12 12-28.5T331-656l-64 64q-68 68-68 162.5T267-267q68 68 163 68t163-68l239-240q12-12 28.5-12t28.5 12q12 12 12 28.5T889-450L649-211q-91 91-219 91t-219-91Zm219-219ZM680-39v-81q66 0 113-47t47-113h81q0 100-70.5 170.5T680-39ZM39-680q0-100 70.5-170.5T280-921v81q-66 0-113 47t-47 113H39Z"/></svg>
                        <h2>Willkommen bei<br />timecloud.at!</h2>
                        <p>Um Ihnen den Einstieg zu erleichtern, haben wir in diesem kurzen Intro, die wichtigsten Infos zusammengefasst und führen einmal durch alle Funktionen. Klicke auf weiter, um ein paar Tipps zu erhalten.</p>
                        <el-button @click="handleSlideNext()" type="white">Weiter</el-button>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide-content">
                        <svg class="mainicon nofill" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path stroke="#ffffff" stroke-width="2" d="M4 5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5ZM14 5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V5ZM4 16a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3ZM14 13a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-6Z"/>
                        </svg>
                        <h2>Dashboard</h2>
                        <p>Hier können alle zugewiesenen Aufgaben eingesehen werden. Swipen Sie diese nach links, um sie als gesehen zu markieren.</p>
                        <el-button @click="handleSlideNext()" type="white">Weiter</el-button>
                        <svg class="arrow arrow--dashboard" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-ea893728=""><path fill="currentColor" d="M544 805.888V168a32 32 0 1 0-64 0v637.888L246.656 557.952a30.72 30.72 0 0 0-45.312 0 35.52 35.52 0 0 0 0 48.064l288 306.048a30.72 30.72 0 0 0 45.312 0l288-306.048a35.52 35.52 0 0 0 0-48 30.72 30.72 0 0 0-45.312 0L544 805.824z"></path></svg>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide-content">
                        <svg class="mainicon" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9688 2C6.44875 2 1.96875 6.48 1.96875 12C1.96875 17.52 6.44875 22 11.9688 22C17.4888 22 21.9688 17.52 21.9688 12C21.9688 6.48 17.4988 2 11.9688 2ZM11.9987 16.23C9.65875 16.23 7.76875 14.34 7.76875 12C7.76875 9.66 9.65875 7.77 11.9987 7.77C14.3387 7.77 16.2287 9.66 16.2287 12C16.2287 14.34 14.3387 16.23 11.9987 16.23Z" fill="#ffffff"/>
                        </svg>
                        <h2>Record</h2>
                        <p>Gehen Sie das Formular von oben nach unten durch, um eine Zeitaufzeichnung zu erstellen. Baustelle, Datum und Dauer sind Pflichtfelder.</p>
                        <el-button @click="handleSlideNext()" type="white">Weiter</el-button>
                        <svg class="arrow arrow--record" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-ea893728=""><path fill="currentColor" d="M544 805.888V168a32 32 0 1 0-64 0v637.888L246.656 557.952a30.72 30.72 0 0 0-45.312 0 35.52 35.52 0 0 0 0 48.064l288 306.048a30.72 30.72 0 0 0 45.312 0l288-306.048a35.52 35.52 0 0 0 0-48 30.72 30.72 0 0 0-45.312 0L544 805.824z"></path></svg>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide-content">
                        <svg class="mainicon" width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <g color="#000000" font-weight="400" font-family="sans-serif" white-space="normal">
                                <path d="M7.5 1A6.508 6.508 0 0 0 1 7.5C1 11.084 3.916 14 7.5 14S14 11.084 14 7.5 11.084 1 7.5 1zm0 1.04c3.022 0 5.46 2.438 5.46 5.46s-2.438 5.46-5.46 5.46A5.454 5.454 0 0 1 2.039 7.5c0-3.022 2.44-5.46 5.461-5.46z" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;text-transform:none;text-orientation:mixed;shape-padding:0;isolation:auto;mix-blend-mode:normal" overflow="visible"/>
                                <path d="M11.853 11.146l-.707.708 3.5 3.5.707-.708z" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;text-transform:none;text-orientation:mixed;shape-padding:0;isolation:auto;mix-blend-mode:normal" overflow="visible" fill-rule="evenodd"/>
                                <path d="M7 5v2H5v1h2v2h1V8h2V7H8V5z" style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;text-transform:none;isolation:auto;mix-blend-mode:normal;marker:none" overflow="visible"/>
                            </g>
                        </svg>
                        <h2>Details</h2>
                        <p>Wählen Sie eine Baustelle aus - sofern Zusatzinfos oder Dateien an die Baustelle angehängt sind, werden diese aufgelistet.</p>
                        <el-button @click="handleSlideNext()" type="white">Weiter</el-button>
                        <svg class="arrow arrow--details" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-ea893728=""><path fill="currentColor" d="M544 805.888V168a32 32 0 1 0-64 0v637.888L246.656 557.952a30.72 30.72 0 0 0-45.312 0 35.52 35.52 0 0 0 0 48.064l288 306.048a30.72 30.72 0 0 0 45.312 0l288-306.048a35.52 35.52 0 0 0 0-48 30.72 30.72 0 0 0-45.312 0L544 805.824z"></path></svg>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide-content">
                        <svg class="mainicon" width="800px" height="800px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="icon" fill="#ffffff" transform="translate(12.496777, 64.000000)">
                                    <path d="M264.836556,1.42108547e-14 C370.875228,1.42108547e-14 456.836556,85.961328 456.836556,192 C456.836556,298.038672 370.875228,384 264.836556,384 C212.762984,384 165.531274,363.269523 130.944023,329.611165 L160.69855,299.03152 C187.599689,325.209881 224.335206,341.333333 264.836556,341.333333 C347.311079,341.333333 414.169889,274.474523 414.169889,192 C414.169889,109.525477 347.311079,42.6666667 264.836556,42.6666667 C182.362033,42.6666667 115.503223,109.525477 115.503223,192 C115.503223,196.042236 115.663829,200.046961 115.979008,204.008141 L158.169889,161.830111 L188.339779,192 L94.1698893,286.169889 L1.77635684e-14,192 L30.1698893,161.830111 L73.2641059,204.915722 C72.9805787,200.647132 72.836556,196.340385 72.836556,192 C72.836556,85.961328 158.797884,1.42108547e-14 264.836556,1.42108547e-14 Z M286.169889,106.666667 L286.169889,201.813333 L340.569889,238.293333 L317.103223,273.706667 L243.503223,224.853333 L243.503223,106.666667 L286.169889,106.666667 Z" id="Combined-Shape"></path>
                                </g>
                            </g>
                        </svg>
                        <h2>History</h2>
                        <p>Hier können alle Zeitaufzeichnungen von offenen Baustellen eingesehen und bearbeitet werden. Klicken Sie das Icon rechts neben der Überschrift um eine Wochenweise Zeitaufzeichnung zu erhalten.</p>
                        <el-button @click="handleSlideNext()" type="white">Weiter</el-button>
                        <svg class="arrow arrow--history" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-ea893728=""><path fill="currentColor" d="M544 805.888V168a32 32 0 1 0-64 0v637.888L246.656 557.952a30.72 30.72 0 0 0-45.312 0 35.52 35.52 0 0 0 0 48.064l288 306.048a30.72 30.72 0 0 0 45.312 0l288-306.048a35.52 35.52 0 0 0 0-48 30.72 30.72 0 0 0-45.312 0L544 805.824z"></path></svg>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide-content">
                        <svg class="mainicon" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m3.517 17 7.058-11.783a1.667 1.667 0 0 1 2.85 0L20.483 17a1.667 1.667 0 0 1-1.425 2.5H4.942A1.666 1.666 0 0 1 3.517 17ZM12 9a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Zm-1 7a1 1 0 0 1 1-1h.008a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z" /></svg>
                        <h2>Meldungen</h2>
                        <p>Benutzen Sie den "Meldungen" Button, um eine Nachricht an den Admin zu senden. Diese Meldungen sind für alle Administratoren sichtbar.</p>
                        <el-button v-if="user.permissions.indexOf('admin') < 0" @click="handleClose()" type="white">Schließen</el-button>
                        <el-button v-else @click="handleSlideNext()" type="white">Weiter</el-button>
                        <svg class="arrow arrow--alerts" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-ea893728=""><path fill="currentColor" d="M256 256h416a32 32 0 1 0 0-64H224a32 32 0 0 0-32 32v448a32 32 0 0 0 64 0z"></path><path fill="currentColor" d="M246.656 201.344a32 32 0 0 0-45.312 45.312l544 544a32 32 0 0 0 45.312-45.312l-544-544z"></path></svg>
                    </div>
                </swiper-slide>
                <swiper-slide v-if="user.permissions.indexOf('admin') >= 0">
                    <div class="swiper-slide-content">
                        <svg class="mainicon nofill" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></svg>
                        <h2>Einstellungen</h2>
                        <p>Sollten spontan neue Materialien oder Baustellen benötigt werden, können diese einfach und schnell von Administratoren auch über die App erstellt werden.</p>
                        <el-button @click="handleClose()" type="white">Schließen</el-button>
                        <svg class="arrow arrow--settings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-ea893728=""><path fill="currentColor" d="M572.235 205.282v600.365a30.118 30.118 0 1 1-60.235 0V205.282L292.382 438.633a28.913 28.913 0 0 1-42.646 0 33.43 33.43 0 0 1 0-45.236l271.058-288.045a28.913 28.913 0 0 1 42.647 0L834.5 393.397a33.43 33.43 0 0 1 0 45.176 28.913 28.913 0 0 1-42.647 0l-219.618-233.23z"></path></svg>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/pagination';

export default {
	name: 'OnboardingScreens',

    components: {
      Swiper,
      SwiperSlide,
    },

    setup() {
        return {
            modules: [Pagination],
        };
    },

	computed: {
		...mapGetters({
			onboarding: 'onboarding',
			user: 'user',
		})
	},

	data: () => ({
        sw: null
	}),

	methods: {
        onSwiper(swiper) {
            this.sw = swiper;
        },
        handleSlideNext() {
            this.sw.slideNext();
        },
        handleClose() {
            this.$store.dispatch('onboardingDone');
        },
	}
}
</script>

<style lang="scss">
.onboarding {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 11000;
    top: 0;
    left: 0;
    background: transparent;

    &__content {
        background: rgba(0, 0, 0, 0.9);
        position: fixed;
        width: calc(100% - 40px);
        height: calc(100% - 190px);
        top: 70px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 15px;
        max-width: 520px;
    }
    &__remove {
        position: absolute;
        bottom: 70px;
        color: white;
        left: 50%;
        transform: translateX(-50%);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
        font-size: 12px;
        z-index: 100;
        display: block;
        width: 100%;

        > span {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -2px;
                height: 1px;
                background: white;
                width: 100%;
            }
        }

        @media screen and (max-height: 670px){
            bottom: 55px;
        }
    }

    .swiper {
        height: calc(100% - 25px);

        @media screen and (max-height: 670px){
            height: calc(100% - 10px);
        }

        &-wrapper {
            height: 100%;
        }
        &-slide {
            height: 100%;
            background: transparent;

            &-content {
                height: calc(100% - 55px);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                @media screen and (max-height: 670px){
                    justify-content: flex-start;
                    padding-top: 20px;
                }

                .mainicon {
                    fill: white;
                    width: 30px !important;
                    height: auto !important;

                    &.nofill {
                        stroke: white;
                        color: white;
                        fill: none;
                    }
                }
                h2 {
                    color: white;
                    margin: 20px 0;
                    text-align: center;
                    padding: 0;
                    box-sizing: border-box;

                    @media screen and (max-height: 670px){
                        margin: 15px 0;
                    }
                }
                p {
                    color: white;
                    width: 100%;
                    text-align: left;
                    padding: 0 25px;
                    box-sizing: border-box;
                    font-size: 18px;
                    margin: 0 0 20px;

                    @media screen and (max-height: 765px){
                        font-size: 16px;
                    }
                }
                button {
                    margin-top: 25px;

                    @media screen and (max-height: 670px){
                        margin-top: 5px;
                    }
                }

                .arrow {
                    position: absolute;
                    width: 28px;
                    height: auto;
                    color: white;
                    bottom: 90px;
                    left: calc(50% - 37.5%);
                    transform: translateX(-50%);
                    
                    @media screen and (max-height: 670px){
                        bottom: 80px;
                        width: 25px;
                    }

                    &--record {
                        left: calc(50% - 12.5%);
                    }
                    &--details {
                        left: calc(50% + 12.5%);
                    }
                    &--history {
                        left: calc(50% + 37.5%);
                    }
                    &--alerts {
                        bottom: auto;
                        top: 15px;
                        left: 65px;
                    }
                    &--settings {
                        bottom: auto;
                        top: 15px;
                        left: 55px;
                    }
                }
            }
        }
        &-pagination {
            color: white;
            font-size: 12px;
            font-weight: 700;
        }
    }
}
</style>