<template>
	<div class="uiblock" :class="{ 'uiblock--local' : !global }">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
	</div>
</template>

<script>
export default {
	name: 'UiBlock',

    props: {
        global: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss">
.uiblock {
    position: fixed;
    z-index: 20000;
    background: rgba(0,0,0,.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    &--local {
        position: absolute;
        width: 100%;
        height: 100%;
        background: none;

        .lds-ring div {
            border-color: #000 transparent transparent transparent !important;
        }
    }

    .lds-ring {
        display: inline-block;
        position: absolute;
        width: 44px;
        height: 44px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>