<template>
	<div class="login content">
		<div class="login__content">

			<div class="login__logo">
				<img :src="clientconfig.logo" />
			</div>

			<h1>{{ title }}</h1>

			<div class="login__wrapper">
				<div class="login__inputs">
					<span class="smallheadline">Passwort</span>
					<el-input v-model="password" type="password" autocomplete-emits="off" />
				</div>
				<div class="login__inputs">
					<span class="smallheadline">Passwort wiederholen</span>
					<el-input v-model="passwordrepeat" type="password" autocomplete-emits="off" />
				</div>

				<el-button class="loginbutton" @click="setPassword()">{{ title }}</el-button>

			</div>

		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as api from "@/api";
import router from '../router';
import { ElMessage } from 'element-plus';

export default {
	name: 'SetPasswordView',

	data: () => ({
		passwordrepeat: '',
		password: '',
		email: '',
		token: '',
		type: router.currentRoute._value.name,
		title: 'Passwort setzen',
		success: 'Passwort gesetzt!'
	}),

	computed: {
		...mapGetters({
			clientconfig: 'clientconfig'
		})
	},

	created() {
		if(this.clientconfig === false) this.$store.dispatch('fetchClientConfig');

		if(this.type === 'ResetpasswordView') this.title = 'Passwort zurücksetzen';

		this.checkHash();
	},
	
	methods: {
		checkHash(){
            if((this.type === 'SetpasswordView' && typeof router.currentRoute._value.query.hash === 'undefined') || (this.type === 'ResetpasswordView' && typeof router.currentRoute._value.query.token === 'undefined')) router.push({ name: "LoginView" });
		},
        async setPassword(){
            try {
                if(this.password.length >= 6 && this.password === this.passwordrepeat){
                    const senddata = {
                        password_confirmation: this.passwordrepeat,
                        password: this.password,
                    };

					if(this.type === 'ResetpasswordView'){
						senddata.token = router.currentRoute._value.query.token;
						senddata.email = router.currentRoute._value.query.email;
						this.success = 'Passwort zurückgesetzt!';
						await api.user.resetPassword(senddata);
					} else {
						senddata.token = router.currentRoute._value.query.hash;
						await api.user.acceptInvite(senddata);
					}

					ElMessage({
						message: this.success,
						type: 'success',
					});

                    router.push({ name: "LoginView" });
                } else {
					ElMessage({
						message: 'Das Passwort muss mindestens 6 Zeichen lang sein und übereinstimmen.',
						type: 'error',
					});
                }

            } catch (error) {
                console.error(error);
            }
        }
	}
}
</script>

<style lang="scss">

</style>