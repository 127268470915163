<template>
	<div class="details content">

        <select-popup :data="selectPopupData" :value="selectedValue" :selected="selectedValues" :datatype="selectPopupType" @close="dialogClosed"></select-popup>

        <h1>Baustellendetails</h1>

        <p v-if="sites.length < 1" class="nodata">Keine Baustellen vorhanden!</p>

        <div v-if="sites.length > 0" class="el-form">
            <div class="input__wrap">
                <input type="text" :value="siteById" @click="openSelect()" placeholder="Baustelle auswählen" readonly />
            </div>
        </div>

        <div v-if="details !== false" class="details__content">
            <h2 class="smallheadline">Zusatzinformationen:</h2>
            <div v-if="details.info && details.info.length > 0" v-html="brokenDetails" class="details__info"></div>
            <div v-else class="details__nodata">Keine Zusatzinformationen vorhanden.</div>
            <br />
            <h2 class="smallheadline">Daten:</h2>
            <ul v-if="details.attachments && details.attachments.length > 0" class="details__filelist">
                <li v-for="(f, i) in details.attachments" :key="'files_' + i" class="details__filelist__item">
                    <a @click="loadFile(f.id)">
                        <span class="name">{{ f.file_name }}</span>
                        <span class="extension smallheadline">{{ f.extension }}</span>
                    </a>
                </li>                
            </ul>
            <div v-else class="details__nodata">
                Keine Daten vorhanden.
            </div>
        </div>

		<footer-bar />

	</div>
</template>

<script>
import * as api from "@/api";

import { mapGetters } from 'vuex';
import footerBar from '../components/FooterBar.vue';
import selectPopup from '../components/SelectPopup.vue';
//import router from '../router';

export default {
	name: 'RecordView',

    components: {
        footerBar,
        selectPopup,
    },

	data: () => ({
        selectedValues: [],
        selectedValue: 0,
        selectPopupData: { all: [], recent: [] },
        selectPopupType: '',

        selectedSite: 0,
        details: false
	}),

	computed: {
		...mapGetters({
            sites: 'sites',
            recentSites: 'recentSites',
		}),
        siteById(){
            let retval = '';
            this.sites.forEach(s => {
                if(Number(s.id) === Number(this.selectedSite)){
                    retval = s.name;
                }
            });
            return retval;
        },
		brokenDetails(){
            let retval = '';
            if(this.details.info && this.details.info.length > 0){
                retval = this.details.info.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
            return retval;
		},
	},

	created() {
        if(this.sites.length < 1){
            this.$store.commit('set_blockui', true);
            this.fetchData();  
        } else {
            this.$store.dispatch('fetchSites');
        }
	},
	
	methods: {
        fetchData(){
            if(this.sites.length < 1){
                this.$store.dispatch('fetchSites');
                this.$store.commit('set_blockui', false);
            }
        },
        openSelect(){
            this.selectedValues = [];
            this.selectedValue = (isNaN(this.selectedSite)) ? 0 : Number(this.selectedSite);
            this.selectPopupData = { all: this.sites, recent: this.recentSites };
            this.selectPopupType = 'constructionsite';
        },
        resetSelect(){
            this.selectedValues = [];
            this.selectedValue = 0;
            this.selectPopupData = { all: [], recent: [] };
            this.selectPopupType = '';
        },
        async dialogClosed(selected){
            this.$store.commit('set_blockui', true);
            this.resetSelect();
            if(selected !== false){
                this.selectedSite = selected.id;
                const response = await this.$store.dispatch('fetchSiteDetails', this.selectedSite);
                this.details = response.data;
            }
            this.$store.commit('set_blockui', false);
        },
        async loadFile(id){
			try {
                let refWindow = window.open();
                const response = await api.constructionsite.fetchFile(this.selectedSite, id);
                refWindow.location = URL.createObjectURL(response.data);
			} catch (error) {
				console.error(error);
			}
        },
        base64ToBlob( base64, type = "application/octet-stream" ) {
            const binStr = atob( base64 );
            const len = binStr.length;
            const arr = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                arr[ i ] = binStr.charCodeAt( i );
            }
            return new Blob( [ arr ], { type: type } );
        },
	}
}
</script>

<style lang="scss" scoped>
input {
    width: 100%;
    cursor: pointer;
}

.details {
    &__content {
        padding-top: 20px;
        text-align: left;

        > .smallheadline {
            font-size: 11px;
            margin-bottom: 5px;
        }
    }

    &__info {
        border: solid 2px $color-black;
        padding: 11px 18px;
        border-radius: 17px;
        text-align: left;
        font-size: 15px;
        color: $color-black;
        font-weight: 700;
        line-height: 22px;
    }

    &__filelist {
        list-style-type: none;

        &__item {
            border: solid 2px $color-black;
            padding: 11px 18px;
            border-radius: 17px;
            margin-bottom: 8px;
            text-align: left;
            font-size: 15px;
            color: $color-black;
            font-weight: 700;
            position: relative;

            a {
                display: flex;
                width: 100%;
                cursor: pointer;

                span {
                    display: block;

                    &.name {
                        width: calc(100% - 50px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &.extension {
                        width: 50px;
                        text-align: right;
                        padding-top: 4px;
                    }
                }
            }
        }
    }

    &__nodata {
        text-align: center;
        opacity: .3;
        margin: 20px 0;
        font-style: italic;
        font-size: 14px;
    }
}
</style>