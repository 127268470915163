<template>
	<div class="login content">
		<div class="login__content">

			<div class="login__logo">
				<img :src="clientconfig.logo" />
			</div>

			<h1>Passwort vergessen</h1>

			<div class="login__wrapper">
				<div class="login__inputs">
					<span class="smallheadline">E-Mail</span>
					<el-input v-model="username" autocomplete="off" />
				</div>

				<el-button class="loginbutton" @click="reset()">Zurücksetzen</el-button>

                <router-link to="/login" class="textlink login__forgot">Zurück</router-link>

			</div>

		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as api from "@/api";
import { ElMessage } from 'element-plus'

export default {
	name: 'ForgotPasswordView',

	data: () => ({
		username: '',
	}),

	computed: {
		...mapGetters({
			clientconfig: 'clientconfig'
		})
	},

	created() {
		if(this.clientconfig === false) this.$store.dispatch('fetchClientConfig');
	},
	
	methods: {
        async reset(){
            try {
				const senddata = {
					email: this.username
				};

				const response = await api.user.triggerPasswordReset(senddata);
				this.username = '';
				
				ElMessage({
					message: response.data.message,
					type: 'success',
				});
            } catch (error) {
				console.error(error);
            }
        },
	}
}
</script>

<style lang="scss">

</style>