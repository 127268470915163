<template>
	<div class="selectpopup">

        <el-dialog v-model="dialogVisible">
            <div class="selectpopup__top">
                <div class="selectpopup__back" @click="forceClose">
                    <svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <polyline data-name="Right" fill="none" points="7.6 7 2.5 12 7.6 17" stroke="#1f1f1f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <line fill="none" stroke="#1f1f1f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="21.5" x2="4.8" y1="12" y2="12"/>
                        </g>
                    </svg>
                </div>
                <input v-model="search" :placeholder="placeholder" class="selectpopup__filter" />
            </div>
            <div class="selectpopup__content">
                <h6 v-if="search.length < 1 && data.recent.length > 0" class="smallheadline">Zuletzt verwendet</h6>
                <ul v-if="search.length < 1 && data.recent.length > 0">
                    <li v-for="d in data.recent" :key="d.id" @click="selectItem(d.id)" :class="{ active : value === Number(d.id), selected : selected.indexOf(d.id) >= 0 }">
                        <span class="check"></span>
                        <span class="name">{{ d.name }}</span>
                    </li>
                </ul>
                <h6 v-if="search.length < 1" class="smallheadline">Alle</h6>
                <ul>
                    <li v-for="d in filteredSites" :key="d.id" @click="selectItem(d.id)" :class="{ active : value === Number(d.id), selected : selected.indexOf(d.id) >= 0 }">
                        <span class="check"></span>
                        <span class="name">{{ d.name }}</span>
                    </li>
                </ul>
            </div>
        </el-dialog>

	</div>
</template>

<script>
export default {
	name: 'SelectPopup',

    props: {
        data: {
            type: Array,
            default: new Array()
        },
        datatype: {
            type: String,
            default: ''
        },
        value: {
            type: Number,
            default: 0
        },
        selected: {
            type: Array,
            default: new Array()
        }
    },

    watch: {
        data() {
            if(this.data.all.length > 0){
                this.dialogVisible = true;
                this.search = '';

                this.placeholder = (this.datatype === 'materials') ? 'Material filtern' : 'Baustellen filtern';
            }
        }
    },

    computed: {
        filteredSites() {
            let retval = this.data.all;
            const tempdata = [];

            if(this.search.length > 0){
                this.data.all.forEach(d => {
                    if(d.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0){
                        tempdata.push(d);
                    }
                });
            } else {
                this.data.all.forEach(d => {
                    tempdata.push(d);
                });
            }

            retval = tempdata;
            return retval;
        }
    },

	data: () => ({
		dialogVisible: false,
        search: '',
        placeholder: ''
	}),

	mounted() {},
	
	methods: {
        closeDialog(id = false){
            this.$emit('close', { id: id, type: this.datatype });
            this.dialogVisible = false;
        },
        selectItem(id){
            this.closeDialog(id);
        },
        forceClose(){
            this.$emit('close', false);
            this.dialogVisible = false;
        }
	}
}
</script>

<style lang="scss">
.selectpopup {
    .el-overlay {
        z-index: 10001 !important;
    }
    .el-dialog {
        width: 100vw !important;
        height: 100vh;
        margin: 0;
        padding: 0;
        max-width: none !important;

        &__headerbtn {
            display: none;
        }
        &__body {
            background: white;
        }

        .selectpopup {
            &__content {
                padding: 45px 20px;

                max-width: 600px;
                margin: 0 auto;

                .smallheadline {
                    padding-left: 0;
                }
                ul {
                    padding-left: 0;
                }
            }
        }

        h6 {
            text-align: left;
            padding-left: 10px;
            margin-bottom: 8px;
        }

        ul {
            list-style-type: none;

            & + h6 {
                margin-top: 20px;
            }

            li {
                border: solid 2px $color-black;
                padding: 11px 18px 11px 34px;
                border-radius: 17px;
                margin-bottom: 8px;
                text-align: left;
                font-size: 15px;
                color: $color-black;
                font-weight: 700;
                position: relative;
                cursor: pointer;

                .name {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .check {
                    display: block;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: 7px;
                    top: 50%;
                    transform: translateY(-50%);

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%) rotate(-45deg);
                        width: 10px;
                        height: 2px;
                        background: $color-black;
                        margin-left: 3px;
                        display: none;
                    }
                    &:after {
                        transform: translate(-50%, -50%) rotate(45deg);
                        width: 4px;
                        margin-left: -2px;
                        margin-top: 1px;
                    }
                }

                &.selected {
                    opacity: .25;
                    pointer-events: none;

                    .check {
                        &:before,
                        &:after {
                            display: block;
                        }
                    }
                }
                &.active {
                    opacity: 1;
                    pointer-events: initial;

                    .check {
                        &:before,
                        &:after {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &__filter {
        font-size: 14px;
        font-weight: 700 !important;
        border-radius: 15px !important;
        letter-spacing: .02em;
        font-weight: normal;
        box-sizing: border-box;
        padding: 14px 20px;
        border-radius: 5px;
        margin-bottom: 5px;
        border: none;
        width: calc(100% - 40px);
        background: #efefef;
    }
    &__top {
        display: flex;
        justify-content: space-between;

        width: calc(100% - 40px);
        position: fixed;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        background: white;
        border-top: solid 40px white;
        max-width: 600px;
    }
    &__back {
        width: 35px;
        height: 50px;
        display: block;
        position: relative;
        cursor: pointer;

        svg {
            width: 26px;
            height: auto;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: -3px;
        }
    }
}
</style>