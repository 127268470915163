<template>
	<div class="menubar">
        
        <div class="menubar__alert" @click="openAlert">
            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m3.517 17 7.058-11.783a1.667 1.667 0 0 1 2.85 0L20.483 17a1.667 1.667 0 0 1-1.425 2.5H4.942A1.666 1.666 0 0 1 3.517 17ZM12 9a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Zm-1 7a1 1 0 0 1 1-1h.008a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z" fill="#000000"/></svg>
        </div>
        <div v-if="permissions.admin" class="menubar__settings" @click="openSettings">
            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></svg>
        </div>

        <div class="menubar__logo">
            <img v-if="clientconfig !== false" :src="clientconfig.logo" />
        </div>

        <div v-if="user !== false" class="menubar__user" @click="logout">
            {{ user.first_name }} {{ user.last_name }}<br />
            <span>Ausloggen</span>
        </div>

        <div v-if="blockui" class="uiblock">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
// import router from '../router';

export default {
	name: 'MenuBar',

	data: () => ({
		sidemenu: false
	}),

	computed: {
		...mapGetters({
			user: 'user',
			blockui: 'blockui',
			showAlert: 'showAlert',
			clientconfig: 'clientconfig',
            permissions: 'permissions'
		})
	},

	created() {
        // fetch client config if not already done at login
        if(this.clientconfig === false) this.$store.dispatch('fetchClientConfig');

		// check if token there and try to fetch user
		if(localStorage.getItem('token') !== null){
            this.$store.dispatch('fetchMe');

        // no user, return to login
        } else {
            this.logout();
        }
	},
	
	methods: {
		logout(){
			this.$store.dispatch('logout', true);
		},
        openAlert(){
            this.$store.commit('set_showAlert', true);
        },
        openSettings(){
            this.$store.commit('set_showSettings', true);
        }
	}
}
</script>

<style lang="scss">
.menubar {
    display: block;
    justify-content: space-between;
    height: 60px;
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    z-index: 10000;
    background-color: white;
    max-width: 560px;
    transform: translate(-50%);

    &__settings,
    &__alert {
        position: absolute;
        top: 7px;
        left: 9px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        
        svg {
            width: 35px;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: $color-black;
        }
    }
    &__settings {
        left: 55px;

        svg {
            width: 29px;
        }
    }
    &__logo {
        width: 130px;
        height: calc(100% - 10px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 5px;
        align-items: center;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__user {
        position: absolute;
        right: 15px;
        top: 15px;
        text-align: right;
        font-weight: bold;
        line-height: .95;
        width: calc(50% - 90px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        cursor: pointer;

        span {
            font-size: 11px;
            font-weight: normal;
        }
    }
}

.uiblock {
    position: fixed;
    z-index: 20000;
    background: rgba(0,0,0,.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .lds-ring {
        display: inline-block;
        position: absolute;
        width: 44px;
        height: 44px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>