<template>
	<div v-if="showAlert" class="alert">
		<el-dialog v-model="showAlert" :title="'Meldung schreiben'" @close="closeDialog">

			<el-form :model="form">

				<el-form-item>
					<div class="input__wrap input__wrap--noicon" :class="{ error : errors.indexOf('text') >= 0 }">
						<textarea v-model="form.text"></textarea>
					</div>
				</el-form-item>

				<span class="alert__footer">
					<el-button @click="saveData" type="primary">Absenden</el-button>
					<span class="textlink" @click="closeDialog">Abbrechen</span>
				</span>

			</el-form>

		</el-dialog>
	</div>

	<div v-if="showSettings" class="settings">
		<el-dialog v-model="showSettings" :title="'Einstellungen'" @close="closeDialog">

			<el-form :model="form">

				<el-form-item class="settings__input">
                    <input type="text" v-model="form.site" placeholder="Baustelle" />
					<el-button type="primary" @click="addSite">+</el-button>
				</el-form-item>
				<el-form-item class="settings__input">
                    <input type="text" v-model="form.material" placeholder="Material" />
					<el-button type="primary" @click="addMaterial">+</el-button>
				</el-form-item>

				<span class="settings__footer">
					<span class="textlink" @click="closeDialog">Abbrechen</span>
				</span>

			</el-form>

		</el-dialog>
	</div>

	<menu-bar v-if="$router.currentRoute.value.meta.navigation"></menu-bar>
	<onboarding-screens />
	<router-view/>
</template>


<script>
import * as api from "@/api";
import { mapGetters } from 'vuex';
import { ElMessage } from 'element-plus';
import menuBar from './components/MenuBar.vue';
import onboardingScreens from './components/OnboardingScreens.vue';

export default {
	name: 'App',

    components: {
        menuBar,
        onboardingScreens,
    },

	data: () => ({
		form: {
			text: '',
			site: '',
			material: ''
		},
		errors: []
	}),

	computed: {
		...mapGetters({
			showAlert: 'showAlert',
			showSettings: 'showSettings',
			user: 'user',
		})
	},

	methods: {
		closeDialog() {
			this.form.text = '';
			this.form.site = '';
			this.form.material = '';

			this.$store.commit('set_showAlert', false);
			this.$store.commit('set_showSettings', false);
		},
        async saveData(){
            try {
				this.errors = [];
                if(this.form.text.length < 1){
					this.errors.push('text');
				} else {
					await api.alert.create({
						description: this.form.text,
						// user_id: this.user.id
					});
                    
                    ElMessage({
                        message: 'Meldung erfolgreich abgeschickt.',
                        type: 'success',
                    });

					this.closeDialog();
                }
            } catch (error) {
                console.error(error);
            }
        },
		async addMaterial(){
			try {
				await api.material.create({ name: this.form.material });
				this.$store.dispatch('fetchMaterials');
				ElMessage({
					message: 'Material erstellt.',
					type: 'success',
				});
				this.form.material = '';
			} catch (error) {
				console.error(error);
			}
		},
		async addSite(){
			try {
				await api.constructionsite.create({ name: this.form.site });
				this.$store.dispatch('fetchSites');
				ElMessage({
					message: 'Baustelle erstellt.',
					type: 'success',
				});
				this.form.site = '';
			} catch (error) {
				console.error(error);
			}
		}
	}
}
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;

	max-width: 600px;
	margin: 0 auto;
}

.settings,
.alert {
	.el-dialog {
		width: 90%;

		textarea {
			height: 200px;
		}
	}

    &__footer {
        display: flex;
        gap: 10px;
        padding-bottom: 10px;

        .textlink {
            min-width: 76px;
        }
    }
}
.settings {
	&__input {
		.el-form-item__content {
			display: flex;
			gap: 0 10px;

			input {
				flex-grow: 1;
			}
			button {
				width: 38px;
				text-align: center;
				font-size: 18px !important;
				padding: 10px !important;
				text-indent: 1px;
			}
		}
	}
}
</style>
