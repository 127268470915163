<template>
	<div class="dashboard content">

		<h1>Dashboard</h1>

		<div class="dashboard__wrapper">
			<h2>Aktuelle Aufgaben</h2>

			<div class="dashboard__alerts" ref="alerts">
				<div v-for="a in alerts" :key="'alert_' + a.id" v-touch:swipe.left="swipeHandler" v-touch:swipe.right="swipeHandler" class="dashboard__alerts__item js-dashboard-alert">
					<div class="dashboard__alerts__item__inner">
						{{ a.text }}
					</div>
					<div class="dashboard__alerts__done smallheadline" @click="checkAlert(a.id)"><span>Erledigt</span></div>
					<div class="dashboard__alerts__cancel smallheadline" @click="cancelAlert"><span>Abbr.</span></div>
				</div>
				<div v-if="alerts.length === 0" class="dashboard__alerts__nodata">
					Keine aktuellen Aufgaben
				</div>
			</div>
		</div>

		<footer-bar />

	</div>
</template>

<script>
import * as api from "@/api";
import footerBar from '../components/FooterBar.vue';
import { ElMessage } from 'element-plus'

export default {
	name: 'DashboardView',

    components: {
        footerBar,
    },

	data: () => ({
		alerts: [],
	}),

	created() {
		this.fetchAlerts();
	},
	
	methods: {
		async fetchAlerts(){
			try {
				const response = await api.alert.get();
				this.alerts = response.data.data.filter(obj => obj.type === 'to_users');
                    
				// ElMessage({
				// 	message: 'Task erfolgreich gespeichert.',
				// 	type: 'success',
				// });

				const that = this
				setTimeout(function(){
					const firstAlert = that.$refs.alerts.querySelector('.js-dashboard-alert:first-child');
					firstAlert.classList.add('howto');

					setTimeout(function(){
						firstAlert.classList.remove('howto');
					}, 800)
				})
            } catch (error) {
                console.error(error);
            }
		},
		async checkAlert(id){
			this.$store.commit('set_blockui', true);

			try {
				await api.alert.done(id);
				await this.fetchAlerts();

				this.$store.commit('set_blockui', false);
                    
				ElMessage({
					message: 'Aufgabe erledigt.',
					type: 'success',
				});
            } catch (error) {
				this.$store.commit('set_blockui', false);
                console.error(error);
            }
		},
		cancelAlert(){
			document.querySelectorAll('.js-dashboard-alert').forEach(el => {
				el.classList.remove('active');
			});
		},
        swipeHandler(item, i) {
            let el = i.target;

            if(!el.classList.contains('js-dashboard-alert')){
                el = i.target.closest('.js-dashboard-alert');
            }
            
            if(item === 'left'){
                el.classList.add('active');
            } else if(item === 'right'){
                el.classList.remove('active');
            }
        },
	}
}
</script>

<style lang="scss" scoped>
.dashboard {
	&__wrapper {
		// display: flex;
		// gap: 10px;
		// flex-wrap: wrap;
	}
	&__button {
		position: relative;
		background: #eee;
		width: calc(50% - 5px);
		border-radius: 10px;

		> span {
			display: block;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			position: relative;

			> span {
				position: absolute;
				width: calc(100% - 40px);
				left: 20px;
				bottom: 20px;
				font-weight: bold;
				text-align: left;
				color: #1f1f1f;
			}
		}

		svg,
		svg path {
			width: 25px;
			height: auto;
			fill: var(--ci);
			position: absolute;
			left: 20px;
			top: 20px;
		}
	}
	&__item {
		background: white;
		border-radius: 15px;
		padding: 10px;
		box-sizing: border-box;
		width: calc(50% - 5px);
		height: 200px;
		position: relative;
		
		background: #f9f9f9; /* Old browsers */
		background: -moz-linear-gradient(-45deg,  #f9f9f9 0%, #dddddd 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(-45deg,  #f9f9f9 0%,#dddddd 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(135deg,  #f9f9f9 0%,#dddddd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#dddddd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

		&__text {
			position: absolute;
			bottom: 10px;
			left: 12px;
			text-align: left;
		}
		&__headline {
			display: block;
			margin-bottom: 3px;
		}
		&__content {
			> span {
				color: #AD1C21;
				font-weight: bold;
			}
		}
	}

	&__alerts {
		display: block;

		&__item {
            overflow: hidden;
            position: relative;
            margin-bottom: 10px;
            border-radius: 6px;
            z-index: 5;
			text-indent: -4px;

            &.active {
                .dashboard__alerts {
					&__item {
						&__inner {
							transform: translateX(-140px);
						}
					}
                    &__done,
                    &__cancel {
                        opacity: 1;
                        transition: all .15s linear 0s;
                    }
                }
            }

			&__inner {
				display: block;
				background: #eee;
				text-align: left;
				padding: 12px 15px;
				line-height: 1.3;
				position: relative;
				z-index: 10;
				border-radius: 6px;
				transition: all .3s ease;
			}

			&.howto {
				.dashboard__alerts {
					&__item__inner {
						animation-name: how;
						animation-duration: .8s;
					}
					&__done,
					&__cancel {
						opacity: 1;
					}
				}
			}
		}
		&__done,
		&__cancel {
			position: absolute;
			right: 0;
			width: 76px;
			background: rgba(255, 82, 82, 1);
			height: 100%;
			top: 0;
			z-index: 5;
			border-bottom-right-radius: 6px;
			border-top-right-radius: 6px;
			box-sizing: border-box;
			padding-left: 6px;
			color: white;
			display: flex;
			align-items: stretch;
			opacity: 0;
			transition: all .15s ease .15s;
			cursor: pointer;

			span {
				display: block;
				width: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		&__done {
			right: 70px;
			z-index: 6;
			background: #426ca3;
		}

		&__nodata {
			text-align: center;
			opacity: .3;
			margin: 20px 0;
			font-style: italic;
			font-size: 14px;
		}
	}
}

@keyframes how {
	0% {
		transform: translateX(0);
	}
	55% {
		transform: translateX(-100px);
	}
	95% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(0);
	}
}
</style>